import { buildSchema,buildProperty, buildCollection,ExtraActionsParams } from "@camberi/firecms";





export default class TournamentContestants {

    constructor() {

    }

    static BuildCollection (isadmin:boolean, issuperadmin:boolean, groupname:string) {
      return buildCollection({
        path: "tournamentContestants",
        description: "contestants of tournaments",
        group:groupname,
        exportable:false,
        schema: TournamentContestants.schema(),
        name: "Tournament Contestants",
        permissions: ({entity,path,user,authController,context}) => { 
            return ({
                read: isadmin,
                create: isadmin,
                update: isadmin,
                delete: isadmin
            });
        },
      })
    }

  

    static schema () {
        return buildSchema({
            name: "Tournament Contestants",
            properties: {
              
              usernname: {
                title: "Username",
                dataType: "string"
              },
              email: {
                title: "Username",
                dataType: "string"
              },
              uid: {
                title: "UID",
                dataType: "string"
              },
              
              tournamentsPlayed: {
                dataType:"array",
                of: {
                  dataType: "string"
                }
              },
              globalGameStats: {
                dataType:"map",
                properties: {
                  matchesPlayed: {
                    title: "matches played",
                    dataType: "number"
                  },
                  matchesWon: {
                    title: "matches Won",
                    dataType: "number"
                  },
                  winRate: {
                    title: "Win Rate",
                    dataType: "string"
                  },
                  currentRank: {
                    title: "current rank",
                    dataType: "string"
                  },
                  peakRank: {
                    title: "peak rank",
                    dataType: "string"
                  },
                  completedQuests: {
                    title: "completed quests",
                    dataType: "number"
                  },
                  
                  
                  playTime: {
                    title: "Playtime",
                    dataType: "string"
                  },
                }
              }
              
              
            }
          });
    }


    


    
}