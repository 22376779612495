import { buildSchema,buildProperty, buildCollection,ExtraActionsParams } from "@camberi/firecms";





export default class Tournaments {

    constructor() {

    }

    static BuildCollection (isadmin:boolean, issuperadmin:boolean, groupname:string) {
      return buildCollection({
        path: "tournaments",
        description: "collection of tournaments",
        group:groupname,
        exportable:false,
        schema: Tournaments.schema(),
        name: "Tournaments",
        permissions: ({entity,path,user,authController,context}) => { 
            return ({
                read: isadmin,
                create: isadmin,
                update: isadmin,
                delete: isadmin
            });
        },
      })
    }

  

    static schema () {
        return buildSchema({
            name: "Tournaments",
            properties: {
              
              name: {
                title: "Name",
                dataType: "string"
              },
              description: {
                title: "Description",
                dataType: "string"
              },
              status: {
                title: "status",
                dataType: "string",
                config: {
                  enumValues: {
                      upcoming: "upcoming",
                      inprogress: "inprogress",
                      complete: "complete"
                  }
                },
              },
              winner: {
                
                dataType: "array",
                of: {
                  dataType:"map",
                  properties: {
                    userid: {
                      title: "user ID",
                      dataType: "string"
                    },
                    username: {
                      title: "user name",
                      dataType: "string"
                    },
                  }
                }
                
              },
             
              
              
              contestants: {
                dataType: "array",
                title:"contestants",
                of: {
                  dataType:"map",
                  properties: {
                    userid: {
                      title: "user ID",
                      dataType: "string"
                    },
                    username: {
                      title: "user name",
                      dataType: "string"
                    },
                    
                  }
                }   
              },

              round1matches: this.matchSchema("round 1 matches"),
              round2matches: this.matchSchema("round 2 matches"),
              round3matches: this.matchSchema("round 3 matches"),
              round4matches: this.matchSchema("round 4 matches"),
              round5matches: this.matchSchema("round 5 matches"),
              
            }
          });
    }


    static matchSchema (title:string): any {
      return {
        dataType: "array",
        title: title,
        of: {
          dataType: "map",
          properties: {
            matchStatus: {
              title: "match status",
              dataType: "string",
            },

            sides: {
              dataType: "array",
              title: "sides",
              of: {
                dataType: "map",
                properties: {
                  userid: {
                    title: "user ID",
                    dataType: "string",
                  },  
                  score: {
                    title: "score",
                    dataType: "number",
                  },  
                  
                  isWinner: {
                    title: "is Winner",
                    dataType: "boolean"
                  }
                }
              } 
            }
            
          }
        }
      }
    }


    
}